
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/data/empty/BasicUsage.vue";
import EUICustomImage from "@/views/resources/documentation/element-ui/data/empty/CustomImage.vue";
import EUIImageSize from "@/views/resources/documentation/element-ui/data/empty/ImageSize.vue";
import EUIBottomContent from "@/views/resources/documentation/element-ui/data/empty/BottomContent.vue";

export default defineComponent({
  name: "empty",
  components: {
    EUIBasicUsage,
    EUICustomImage,
    EUIImageSize,
    EUIBottomContent
  },
  setup() {
    setCurrentPageTitle("Empty");
  }
});
